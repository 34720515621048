import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CompetitionEventLookupCode: { input: any; output: any; }
  JSONObject: { input: { [prop: string]: any }; output: { [prop: string]: any }; }
  Timestamp: { input: number; output: number; }
};

export type Actor = Device | User;

export type Athlete = {
  __typename?: 'Athlete';
  category: Category;
  club?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  ijruId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type BatteryStatus = {
  __typename?: 'BatteryStatus';
  automatic: Scalars['Boolean']['output'];
  batteryLevel: Scalars['Int']['output'];
  charging?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type BatteryStatusInput = {
  automatic: Scalars['Boolean']['input'];
  batteryLevel: Scalars['Int']['input'];
  charging?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Category = {
  __typename?: 'Category';
  competitionEventIds: Array<Scalars['CompetitionEventLookupCode']['output']>;
  entries: Array<Entry>;
  entry?: Maybe<Entry>;
  group: Group;
  id: Scalars['ID']['output'];
  judgeAssignments: Array<JudgeAssignment>;
  latestRankedResult?: Maybe<RankedResult>;
  name: Scalars['String']['output'];
  pagePrintConfig: Array<PagePrintConfig>;
  participants: Array<Participant>;
  rankedResult?: Maybe<RankedResult>;
  rankedResults: Array<RankedResult>;
  rulesId: Scalars['String']['output'];
  type: CategoryType;
};


export type CategoryEntriesArgs = {
  competitionEventId?: InputMaybe<Scalars['CompetitionEventLookupCode']['input']>;
};


export type CategoryEntryArgs = {
  entryId: Scalars['ID']['input'];
};


export type CategoryLatestRankedResultArgs = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  maxVisibility?: InputMaybe<ResultVersionType>;
};


export type CategoryRankedResultArgs = {
  resultId: Scalars['ID']['input'];
};


export type CategoryRankedResultsArgs = {
  beforeLockedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  competitionEventId?: InputMaybe<Scalars['CompetitionEventLookupCode']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxVisibility?: InputMaybe<ResultVersionType>;
};

export enum CategoryType {
  Individual = 'Individual',
  Team = 'Team'
}

export type CreateAthleteInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCategoryInput = {
  competitionEventIds?: InputMaybe<Array<Scalars['CompetitionEventLookupCode']['input']>>;
  name: Scalars['String']['input'];
  rulesId: Scalars['String']['input'];
  type: CategoryType;
};

export type CreateEntryInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  heat?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateGroupInput = {
  name: Scalars['String']['input'];
  resultVisibility?: InputMaybe<ResultVisibilityLevel>;
};

export type CreateJudgeAssignmentInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  judgeType: Scalars['String']['input'];
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateJudgeInput = {
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateMarkScoresheetInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CreateTallyScoresheetInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  tally?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CreateTeamInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  members: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Device = {
  __typename?: 'Device';
  battery?: Maybe<BatteryStatus>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  streamShares: Array<DeviceStreamShare>;
};

export type DeviceStreamJudgeInfo = {
  __typename?: 'DeviceStreamJudgeInfo';
  competitionEventId?: Maybe<Scalars['CompetitionEventLookupCode']['output']>;
  judgeType?: Maybe<Scalars['String']['output']>;
  rulesId?: Maybe<Scalars['String']['output']>;
};

export type DeviceStreamJudgeInfoInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  judgeType: Scalars['String']['input'];
  rulesId: Scalars['String']['input'];
};

export type DeviceStreamMarkEvent = {
  __typename?: 'DeviceStreamMarkEvent';
  device: Device;
  info: DeviceStreamJudgeInfo;
  mark: Scalars['JSONObject']['output'];
  sequence: Scalars['Int']['output'];
  tally: Scalars['JSONObject']['output'];
};

export type DeviceStreamShare = {
  __typename?: 'DeviceStreamShare';
  device: Device;
  expiresAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  status: DeviceStreamShareStatus;
  user: User;
};

export enum DeviceStreamShareStatus {
  Accepted = 'Accepted',
  Pending = 'Pending'
}

export type Entry = {
  __typename?: 'Entry';
  category: Category;
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  didNotSkipAt?: Maybe<Scalars['Timestamp']['output']>;
  heat?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lockedAt?: Maybe<Scalars['Timestamp']['output']>;
  participant: Participant;
  pool?: Maybe<Scalars['Int']['output']>;
  scoresheet?: Maybe<Scoresheet>;
  scoresheets: Array<Scoresheet>;
};


export type EntryScoresheetArgs = {
  scoresheetId: Scalars['ID']['input'];
};


export type EntryScoresheetsArgs = {
  since?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type Group = {
  __typename?: 'Group';
  admins: Array<User>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  currentHeat?: Maybe<Scalars['Int']['output']>;
  deviceJudge: Judge;
  entries: Array<Entry>;
  entriesByHeat: Array<Entry>;
  entry?: Maybe<Entry>;
  id: Scalars['ID']['output'];
  judges: Array<Judge>;
  name: Scalars['String']['output'];
  resultVisibility?: Maybe<ResultVisibilityLevel>;
  viewers: Array<User>;
};


export type GroupCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type GroupEntriesByHeatArgs = {
  heat: Scalars['Int']['input'];
};


export type GroupEntryArgs = {
  entryId: Scalars['ID']['input'];
};

export type Judge = {
  __typename?: 'Judge';
  assignments: Array<JudgeAssignment>;
  device?: Maybe<Device>;
  group: Group;
  id: Scalars['ID']['output'];
  ijruId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};


export type JudgeAssignmentsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type JudgeAssignment = {
  __typename?: 'JudgeAssignment';
  category: Category;
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  pool?: Maybe<Scalars['Int']['output']>;
};

export type MarkScoresheet = Scoresheet & {
  __typename?: 'MarkScoresheet';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  device: Device;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  marks: Array<Scalars['JSONObject']['output']>;
  openedAt?: Maybe<Array<Scalars['Timestamp']['output']>>;
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submittedAt?: Maybe<Scalars['Timestamp']['output']>;
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addDeviceStreamMark: DeviceStreamMarkEvent;
  addGroupAdmin: Group;
  addGroupViewer: Group;
  addStreamMark: StreamMarkEvent;
  createAthlete: Athlete;
  createCategory: Category;
  createDeviceStreamShare: DeviceStreamShare;
  createEntry: Entry;
  createGroup: Group;
  createJudge: Judge;
  createJudgeAssignment: JudgeAssignment;
  createMarkScoresheet: MarkScoresheet;
  createTallyScoresheet: TallyScoresheet;
  createTeam: Team;
  deleteCategory: Category;
  deleteDeviceStreamShare: DeviceStreamShare;
  deleteJudgeAssignment: JudgeAssignment;
  deleteParticipant: Participant;
  fillMarkScoresheet: MarkScoresheet;
  fillTallyScoresheet: TallyScoresheet;
  registerDevice: Scalars['String']['output'];
  registerUser: Scalars['String']['output'];
  releaseRankedResultVersion: RankedResult;
  removeGroupAdmin: Group;
  removeGroupViewer: Group;
  reorderEntry: Entry;
  requestDeviceStreamShare: DeviceStreamShare;
  setCurrentHeat: Group;
  setJudgeDevice: Judge;
  setPagePrintConfig: Category;
  setRankedResultVersion: RankedResult;
  setScoresheetExclusion: Scoresheet;
  setScoresheetOptions: Scoresheet;
  toggleEntryLock: Entry;
  toggleGroupComplete: Group;
  unsetJudgeDevice: Judge;
  updateAthlete: Athlete;
  updateCategory: Category;
  updateDeviceStatus: Device;
  updateGroup: Group;
  updateJudge: Judge;
  updateJudgeAssignment: JudgeAssignment;
  updateTeam: Team;
  updateUser: User;
};


export type MutationAddDeviceStreamMarkArgs = {
  info?: InputMaybe<DeviceStreamJudgeInfoInput>;
  mark: Scalars['JSONObject']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationAddGroupAdminArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddGroupViewerArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddStreamMarkArgs = {
  mark: Scalars['JSONObject']['input'];
  scoresheetId: Scalars['ID']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationCreateAthleteArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateAthleteInput;
};


export type MutationCreateCategoryArgs = {
  data: CreateCategoryInput;
  groupId: Scalars['ID']['input'];
};


export type MutationCreateDeviceStreamShareArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationCreateEntryArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateEntryInput;
  participantId: Scalars['ID']['input'];
};


export type MutationCreateGroupArgs = {
  data: CreateGroupInput;
};


export type MutationCreateJudgeArgs = {
  data: CreateJudgeInput;
  groupId: Scalars['ID']['input'];
};


export type MutationCreateJudgeAssignmentArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateJudgeAssignmentInput;
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateMarkScoresheetArgs = {
  data: CreateMarkScoresheetInput;
  entryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateTallyScoresheetArgs = {
  data: CreateTallyScoresheetInput;
  entryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateTeamArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateTeamInput;
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type MutationDeleteDeviceStreamShareArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationDeleteJudgeAssignmentArgs = {
  judgeAssignmentId: Scalars['ID']['input'];
};


export type MutationDeleteParticipantArgs = {
  participantId: Scalars['ID']['input'];
};


export type MutationFillMarkScoresheetArgs = {
  completedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  marks?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  openedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  programVersion?: InputMaybe<Scalars['String']['input']>;
  scoresheetId: Scalars['ID']['input'];
};


export type MutationFillTallyScoresheetArgs = {
  programVersion?: InputMaybe<Scalars['String']['input']>;
  scoresheetId: Scalars['ID']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationRegisterDeviceArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReleaseRankedResultVersionArgs = {
  resultId: Scalars['ID']['input'];
};


export type MutationRemoveGroupAdminArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveGroupViewerArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationReorderEntryArgs = {
  entryId: Scalars['ID']['input'];
  heat?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRequestDeviceStreamShareArgs = {
  deviceId: Scalars['ID']['input'];
};


export type MutationSetCurrentHeatArgs = {
  groupId: Scalars['ID']['input'];
  heat: Scalars['Int']['input'];
};


export type MutationSetJudgeDeviceArgs = {
  deviceId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationSetPagePrintConfigArgs = {
  categoryId: Scalars['ID']['input'];
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  data: SetPagePrintConfigInput;
};


export type MutationSetRankedResultVersionArgs = {
  name: Scalars['String']['input'];
  resultId: Scalars['ID']['input'];
  type: ResultVersionType;
};


export type MutationSetScoresheetExclusionArgs = {
  exclude: Scalars['Boolean']['input'];
  scoresheetId: Scalars['ID']['input'];
};


export type MutationSetScoresheetOptionsArgs = {
  options: Scalars['JSONObject']['input'];
  scoresheetId: Scalars['ID']['input'];
};


export type MutationToggleEntryLockArgs = {
  didNotSkip?: InputMaybe<Scalars['Boolean']['input']>;
  entryId: Scalars['ID']['input'];
  lock: Scalars['Boolean']['input'];
};


export type MutationToggleGroupCompleteArgs = {
  completed: Scalars['Boolean']['input'];
  groupId: Scalars['ID']['input'];
};


export type MutationUnsetJudgeDeviceArgs = {
  judgeId: Scalars['ID']['input'];
};


export type MutationUpdateAthleteArgs = {
  data: UpdateAthleteInput;
  participantId: Scalars['ID']['input'];
};


export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['ID']['input'];
  data: UpdateCategoryInput;
};


export type MutationUpdateDeviceStatusArgs = {
  batteryStatus: BatteryStatusInput;
};


export type MutationUpdateGroupArgs = {
  data: UpdateGroupInput;
  groupId: Scalars['ID']['input'];
};


export type MutationUpdateJudgeArgs = {
  data: UpdateJudgeInput;
  judgeId: Scalars['ID']['input'];
};


export type MutationUpdateJudgeAssignmentArgs = {
  data: UpdateJudgeAssignmentInput;
  judgeAssignmentId: Scalars['ID']['input'];
};


export type MutationUpdateTeamArgs = {
  data: UpdateTeamInput;
  participantId: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PagePrintConfig = {
  __typename?: 'PagePrintConfig';
  competitionEventId?: Maybe<Scalars['CompetitionEventLookupCode']['output']>;
  exclude?: Maybe<Scalars['Boolean']['output']>;
  zoom?: Maybe<Scalars['Float']['output']>;
};

export type Participant = Athlete | Team;

export type Query = {
  __typename?: 'Query';
  group?: Maybe<Group>;
  groups: Array<Group>;
  me?: Maybe<Actor>;
};


export type QueryGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type QueryGroupsArgs = {
  includeCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RankedResult = {
  __typename?: 'RankedResult';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  id: Scalars['ID']['output'];
  maxEntryLockedAt: Scalars['Timestamp']['output'];
  results: Array<Scalars['JSONObject']['output']>;
  versionName?: Maybe<Scalars['String']['output']>;
  versionType: ResultVersionType;
};

export enum ResultVersionType {
  Private = 'Private',
  Public = 'Public',
  Temporary = 'Temporary'
}

export enum ResultVisibilityLevel {
  Live = 'Live',
  Private = 'Private',
  PublicVersions = 'PublicVersions'
}

export type Scoresheet = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  /** @deprecated use excludedAt */
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type SetPagePrintConfigInput = {
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  zoom?: InputMaybe<Scalars['Float']['input']>;
};

export type StreamMarkEvent = {
  __typename?: 'StreamMarkEvent';
  mark: Scalars['JSONObject']['output'];
  scoresheet: Scoresheet;
  sequence: Scalars['Int']['output'];
  tally: Scalars['JSONObject']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  deviceStreamMarkAdded: DeviceStreamMarkEvent;
  heatChanged: Scalars['Int']['output'];
  scoresheetChanged: Scalars['ID']['output'];
  streamMarkAdded: StreamMarkEvent;
};


export type SubscriptionDeviceStreamMarkAddedArgs = {
  deviceIds: Array<Scalars['ID']['input']>;
};


export type SubscriptionHeatChangedArgs = {
  groupId: Scalars['ID']['input'];
};


export type SubscriptionScoresheetChangedArgs = {
  entryIds: Array<Scalars['ID']['input']>;
};


export type SubscriptionStreamMarkAddedArgs = {
  scoresheetIds: Array<Scalars['ID']['input']>;
};

export type TallyScoresheet = Scoresheet & {
  __typename?: 'TallyScoresheet';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  tally?: Maybe<Scalars['JSONObject']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type Team = {
  __typename?: 'Team';
  category: Category;
  club?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  members: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type UpdateAthleteInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryInput = {
  competitionEventIds?: InputMaybe<Array<Scalars['CompetitionEventLookupCode']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  name: Scalars['String']['input'];
  resultVisibility?: InputMaybe<ResultVisibilityLevel>;
};

export type UpdateJudgeAssignmentInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateJudgeInput = {
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  streamShares: Array<DeviceStreamShare>;
};

export type CategoryBaseFragment = { __typename?: 'Category', id: string, name: string, rulesId: string, type: CategoryType };

export type CategoryPrintFragment = { __typename?: 'Category', pagePrintConfig: Array<{ __typename?: 'PagePrintConfig', competitionEventId?: any | null, zoom?: number | null, exclude?: boolean | null }> };

export type CategoryResultsFragment = { __typename?: 'Category', competitionEventIds: Array<any>, participants: Array<{ __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }> };

export type DeviceBaseFragment = { __typename?: 'Device', id: string, name?: string | null, battery?: { __typename?: 'BatteryStatus', automatic: boolean, charging?: boolean | null, batteryLevel: number, updatedAt: number } | null };

export type EntryBaseFragment = { __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null };

export type GroupBaseFragment = { __typename?: 'Group', id: string, name: string, resultVisibility?: ResultVisibilityLevel | null, createdAt: number, completedAt?: number | null, currentHeat?: number | null };

export type JudgeAssignmentFragment = { __typename?: 'JudgeAssignment', id: string, competitionEventId: any, judgeType: string, pool?: number | null, options?: { [prop: string]: any } | null };

export type JudgeBaseFragment = { __typename?: 'Judge', id: string, name: string, ijruId?: string | null };

export type AthleteFragment = { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null };

export type TeamFragment = { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> };

export type RankedResultBaseFragment = { __typename?: 'RankedResult', id: string, versionName?: string | null, versionType: ResultVersionType, competitionEventId: any, maxEntryLockedAt: number, results: Array<{ [prop: string]: any }> };

export type ScoresheetBaseFragment_MarkScoresheet = { __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } };

export type ScoresheetBaseFragment_TallyScoresheet = { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } };

export type ScoresheetBaseFragment = ScoresheetBaseFragment_MarkScoresheet | ScoresheetBaseFragment_TallyScoresheet;

export type MarkScoresheetStatusFragment = { __typename?: 'MarkScoresheet', completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, device: { __typename?: 'Device', id: string, name?: string | null } };

export type MarkScoresheetFragment = { __typename?: 'MarkScoresheet', marks: Array<{ [prop: string]: any }>, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, device: { __typename?: 'Device', id: string, name?: string | null } };

export type TallyScoresheetFragment = { __typename?: 'TallyScoresheet', tally?: { [prop: string]: any } | null };

export type AddGroupAdminMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type AddGroupAdminMutation = { __typename?: 'Mutation', addGroupAdmin: { __typename?: 'Group', id: string, admins: Array<{ __typename?: 'User', id: string }> } };

export type AddGroupViewerMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type AddGroupViewerMutation = { __typename?: 'Mutation', addGroupViewer: { __typename?: 'Group', id: string, viewers: Array<{ __typename?: 'User', id: string }> } };

export type CreateCategoryMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  data: CreateCategoryInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createCategory: { __typename?: 'Category', id: string, name: string, rulesId: string, type: CategoryType } };

export type CreateEntryMutationVariables = Exact<{
  categoryId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
  data: CreateEntryInput;
}>;


export type CreateEntryMutation = { __typename?: 'Mutation', createEntry: { __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, participant: { __typename?: 'Athlete', id: string } | { __typename?: 'Team', id: string } } };

export type CreateGroupMutationVariables = Exact<{
  data: CreateGroupInput;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup: { __typename?: 'Group', id: string, name: string, resultVisibility?: ResultVisibilityLevel | null, createdAt: number, completedAt?: number | null, currentHeat?: number | null } };

export type CreateJudgeMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  data: CreateJudgeInput;
}>;


export type CreateJudgeMutation = { __typename?: 'Mutation', createJudge: { __typename?: 'Judge', id: string, name: string, ijruId?: string | null } };

export type CreateJudgeAssignmentMutationVariables = Exact<{
  categoryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
  data: CreateJudgeAssignmentInput;
}>;


export type CreateJudgeAssignmentMutation = { __typename?: 'Mutation', createJudgeAssignment: { __typename?: 'JudgeAssignment', id: string, competitionEventId: any, judgeType: string, pool?: number | null, options?: { [prop: string]: any } | null } };

export type CreateAthleteMutationVariables = Exact<{
  categoryId: Scalars['ID']['input'];
  data: CreateAthleteInput;
}>;


export type CreateAthleteMutation = { __typename?: 'Mutation', createAthlete: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } };

export type CreateTeamMutationVariables = Exact<{
  categoryId: Scalars['ID']['input'];
  data: CreateTeamInput;
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } };

export type CreateTallyScoresheetMutationVariables = Exact<{
  entryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
  data: CreateTallyScoresheetInput;
}>;


export type CreateTallyScoresheetMutation = { __typename?: 'Mutation', createTallyScoresheet: { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } } };

export type DeleteCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID']['input'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteCategory: { __typename?: 'Category', id: string } };

export type DeleteJudgeAssignmentMutationVariables = Exact<{
  judgeAssignmentId: Scalars['ID']['input'];
}>;


export type DeleteJudgeAssignmentMutation = { __typename?: 'Mutation', deleteJudgeAssignment: { __typename?: 'JudgeAssignment', id: string } };

export type DeleteParticipantMutationVariables = Exact<{
  participantId: Scalars['ID']['input'];
}>;


export type DeleteParticipantMutation = { __typename?: 'Mutation', deleteParticipant: { __typename?: 'Athlete', id: string } | { __typename?: 'Team', id: string } };

export type FillTallyScoresheetMutationVariables = Exact<{
  scoresheetId: Scalars['ID']['input'];
  tally: Scalars['JSONObject']['input'];
  programVersion?: InputMaybe<Scalars['String']['input']>;
}>;


export type FillTallyScoresheetMutation = { __typename?: 'Mutation', fillTallyScoresheet: { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } } };

export type ReleaseRankedResultVersionMutationVariables = Exact<{
  resultId: Scalars['ID']['input'];
}>;


export type ReleaseRankedResultVersionMutation = { __typename?: 'Mutation', releaseRankedResultVersion: { __typename?: 'RankedResult', id: string, versionName?: string | null, versionType: ResultVersionType, competitionEventId: any, maxEntryLockedAt: number, results: Array<{ [prop: string]: any }> } };

export type RemoveGroupAdminMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type RemoveGroupAdminMutation = { __typename?: 'Mutation', removeGroupAdmin: { __typename?: 'Group', id: string, admins: Array<{ __typename?: 'User', id: string }> } };

export type RemoveGroupViewerMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type RemoveGroupViewerMutation = { __typename?: 'Mutation', removeGroupViewer: { __typename?: 'Group', id: string, viewers: Array<{ __typename?: 'User', id: string }> } };

export type ReorderEntryMutationVariables = Exact<{
  entryId: Scalars['ID']['input'];
  heat?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ReorderEntryMutation = { __typename?: 'Mutation', reorderEntry: { __typename?: 'Entry', id: string, heat?: number | null, pool?: number | null } };

export type SetCurrentHeatMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  heat: Scalars['Int']['input'];
}>;


export type SetCurrentHeatMutation = { __typename?: 'Mutation', setCurrentHeat: { __typename?: 'Group', id: string, currentHeat?: number | null } };

export type SetJudgeDeviceMutationVariables = Exact<{
  judgeId: Scalars['ID']['input'];
  deviceId: Scalars['ID']['input'];
}>;


export type SetJudgeDeviceMutation = { __typename?: 'Mutation', setJudgeDevice: { __typename?: 'Judge', id: string, device?: { __typename?: 'Device', id: string, name?: string | null, battery?: { __typename?: 'BatteryStatus', automatic: boolean, charging?: boolean | null, batteryLevel: number, updatedAt: number } | null } | null } };

export type SetPagePrintConfigMutationVariables = Exact<{
  categoryId: Scalars['ID']['input'];
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  data: SetPagePrintConfigInput;
}>;


export type SetPagePrintConfigMutation = { __typename?: 'Mutation', setPagePrintConfig: { __typename?: 'Category', id: string, pagePrintConfig: Array<{ __typename?: 'PagePrintConfig', competitionEventId?: any | null, zoom?: number | null, exclude?: boolean | null }> } };

export type SetRankedResultVersionMutationVariables = Exact<{
  resultId: Scalars['ID']['input'];
  type: ResultVersionType;
  name: Scalars['String']['input'];
}>;


export type SetRankedResultVersionMutation = { __typename?: 'Mutation', setRankedResultVersion: { __typename?: 'RankedResult', id: string, versionName?: string | null, versionType: ResultVersionType, competitionEventId: any, maxEntryLockedAt: number, results: Array<{ [prop: string]: any }> } };

export type SetScoresheetExclusionMutationVariables = Exact<{
  scoresheetId: Scalars['ID']['input'];
  exclude: Scalars['Boolean']['input'];
}>;


export type SetScoresheetExclusionMutation = { __typename?: 'Mutation', setScoresheetExclusion: { __typename?: 'MarkScoresheet', id: string, excludedAt?: number | null } | { __typename?: 'TallyScoresheet', id: string, excludedAt?: number | null } };

export type SetScoresheetOptionsMutationVariables = Exact<{
  scoresheetId: Scalars['ID']['input'];
  options: Scalars['JSONObject']['input'];
}>;


export type SetScoresheetOptionsMutation = { __typename?: 'Mutation', setScoresheetOptions: { __typename?: 'MarkScoresheet', id: string, options?: { [prop: string]: any } | null } | { __typename?: 'TallyScoresheet', id: string, options?: { [prop: string]: any } | null } };

export type ToggleEntryLockMutationVariables = Exact<{
  entryId: Scalars['ID']['input'];
  lock: Scalars['Boolean']['input'];
  didNotSkip: Scalars['Boolean']['input'];
}>;


export type ToggleEntryLockMutation = { __typename?: 'Mutation', toggleEntryLock: { __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null } };

export type ToggleGroupCompleteMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  completed: Scalars['Boolean']['input'];
}>;


export type ToggleGroupCompleteMutation = { __typename?: 'Mutation', toggleGroupComplete: { __typename?: 'Group', id: string, name: string, resultVisibility?: ResultVisibilityLevel | null, createdAt: number, completedAt?: number | null, currentHeat?: number | null } };

export type UnsetJudgeDeviceMutationVariables = Exact<{
  judgeId: Scalars['ID']['input'];
}>;


export type UnsetJudgeDeviceMutation = { __typename?: 'Mutation', unsetJudgeDevice: { __typename?: 'Judge', id: string, device?: { __typename?: 'Device', id: string, name?: string | null, battery?: { __typename?: 'BatteryStatus', automatic: boolean, charging?: boolean | null, batteryLevel: number, updatedAt: number } | null } | null } };

export type UpdateCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID']['input'];
  data: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateCategory: { __typename?: 'Category', competitionEventIds: Array<any>, id: string, name: string, rulesId: string, type: CategoryType } };

export type UpdateGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  data: UpdateGroupInput;
}>;


export type UpdateGroupMutation = { __typename?: 'Mutation', updateGroup: { __typename?: 'Group', id: string, name: string, resultVisibility?: ResultVisibilityLevel | null, createdAt: number, completedAt?: number | null, currentHeat?: number | null } };

export type UpdateJudgeMutationVariables = Exact<{
  judgeId: Scalars['ID']['input'];
  data: UpdateJudgeInput;
}>;


export type UpdateJudgeMutation = { __typename?: 'Mutation', updateJudge: { __typename?: 'Judge', id: string, name: string, ijruId?: string | null } };

export type UpdateJudgeAssignmentMutationVariables = Exact<{
  judgeAssignmentId: Scalars['ID']['input'];
  data: UpdateJudgeAssignmentInput;
}>;


export type UpdateJudgeAssignmentMutation = { __typename?: 'Mutation', updateJudgeAssignment: { __typename?: 'JudgeAssignment', id: string, competitionEventId: any, judgeType: string, pool?: number | null, options?: { [prop: string]: any } | null } };

export type UpdateAthleteMutationVariables = Exact<{
  participantId: Scalars['ID']['input'];
  data: UpdateAthleteInput;
}>;


export type UpdateAthleteMutation = { __typename?: 'Mutation', updateAthlete: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } };

export type UpdateTeamMutationVariables = Exact<{
  participantId: Scalars['ID']['input'];
  data: UpdateTeamInput;
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } };

export type UpdateUserMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, name?: string | null } };

export type CategoryGridQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
}>;


export type CategoryGridQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, category?: { __typename?: 'Category', competitionEventIds: Array<any>, id: string, name: string, rulesId: string, type: CategoryType, participants: Array<{ __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }>, entries: Array<{ __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, participant: { __typename?: 'Athlete', id: string } | { __typename?: 'Team', id: string } }> } | null } | null };

export type CategorySettingsQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
}>;


export type CategorySettingsQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, judges: Array<{ __typename?: 'Judge', id: string, name: string, ijruId?: string | null, assignments: Array<{ __typename?: 'JudgeAssignment', id: string, competitionEventId: any, judgeType: string, pool?: number | null, options?: { [prop: string]: any } | null }> }>, category?: { __typename?: 'Category', competitionEventIds: Array<any>, id: string, name: string, rulesId: string, type: CategoryType, participants: Array<{ __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }> } | null } | null };

export type CurrentHeatScoresheetsQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  heat: Scalars['Int']['input'];
}>;


export type CurrentHeatScoresheetsQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, entriesByHeat: Array<{ __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, scoresheets: Array<{ __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, judge: { __typename?: 'Judge', id: string }, device: { __typename?: 'Device', id: string, name?: string | null } } | { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } }> }> } | null };

export type EntriesWithScoresheetsQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
}>;


export type EntriesWithScoresheetsQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, category?: { __typename?: 'Category', id: string, name: string, rulesId: string, type: CategoryType, judgeAssignments: Array<{ __typename?: 'JudgeAssignment', id: string, competitionEventId: any, judgeType: string, pool?: number | null, options?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string, name: string, ijruId?: string | null } }>, participants: Array<{ __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }>, entries: Array<{ __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, participant: { __typename?: 'Athlete', id: string } | { __typename?: 'Team', id: string }, scoresheets: Array<{ __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, marks: Array<{ [prop: string]: any }>, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, judge: { __typename?: 'Judge', id: string }, device: { __typename?: 'Device', id: string, name?: string | null } } | { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } }> }> } | null } | null };

export type EntryWithScoresheetsQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  entryId: Scalars['ID']['input'];
}>;


export type EntryWithScoresheetsQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, category?: { __typename?: 'Category', id: string, name: string, rulesId: string, type: CategoryType, judgeAssignments: Array<{ __typename?: 'JudgeAssignment', id: string, competitionEventId: any, judgeType: string, pool?: number | null, options?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string, name: string, ijruId?: string | null } }> } | null, entry?: { __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }, scoresheets: Array<{ __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, marks: Array<{ [prop: string]: any }>, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, judge: { __typename?: 'Judge', id: string }, device: { __typename?: 'Device', id: string, name?: string | null } } | { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } }> } | null } | null };

export type GroupInfoQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;


export type GroupInfoQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, name: string, resultVisibility?: ResultVisibilityLevel | null, createdAt: number, completedAt?: number | null, currentHeat?: number | null, admins: Array<{ __typename?: 'User', id: string, name?: string | null }>, viewers: Array<{ __typename?: 'User', id: string, name?: string | null }> } | null };

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, name: string, resultVisibility?: ResultVisibilityLevel | null, createdAt: number, completedAt?: number | null, currentHeat?: number | null, categories: Array<{ __typename?: 'Category', id: string, name: string, rulesId: string, type: CategoryType }> }> };

export type HeatsQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;


export type HeatsQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, name: string, resultVisibility?: ResultVisibilityLevel | null, createdAt: number, completedAt?: number | null, currentHeat?: number | null, categories: Array<{ __typename?: 'Category', id: string, name: string, rulesId: string, type: CategoryType, judgeAssignments: Array<{ __typename?: 'JudgeAssignment', id: string, competitionEventId: any, judgeType: string, pool?: number | null, options?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string, name: string, ijruId?: string | null } }> }>, entries: Array<{ __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, category: { __typename?: 'Category', id: string }, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }, scoresheets: Array<{ __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, judge: { __typename?: 'Judge', id: string }, device: { __typename?: 'Device', id: string, name?: string | null } } | { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, submitterProgramVersion?: string | null, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } }> }> } | null };

export type JudgeStatusesQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;


export type JudgeStatusesQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, judges: Array<{ __typename?: 'Judge', id: string, name: string, ijruId?: string | null, device?: { __typename?: 'Device', id: string, name?: string | null, battery?: { __typename?: 'BatteryStatus', automatic: boolean, charging?: boolean | null, batteryLevel: number, updatedAt: number } | null } | null }> } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'Device', id: string, name?: string | null, battery?: { __typename?: 'BatteryStatus', batteryLevel: number, updatedAt: number } | null } | { __typename?: 'User', id: string, name?: string | null } | null };

export type ResultsQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  singleCategory: Scalars['Boolean']['input'];
}>;


export type ResultsQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, name: string, resultVisibility?: ResultVisibilityLevel | null, createdAt: number, completedAt?: number | null, currentHeat?: number | null, category?: { __typename?: 'Category', id: string, name: string, rulesId: string, type: CategoryType, competitionEventIds: Array<any>, rankedResults: Array<{ __typename?: 'RankedResult', id: string, versionName?: string | null, versionType: ResultVersionType, competitionEventId: any, maxEntryLockedAt: number, results: Array<{ [prop: string]: any }> }>, pagePrintConfig: Array<{ __typename?: 'PagePrintConfig', competitionEventId?: any | null, zoom?: number | null, exclude?: boolean | null }>, participants: Array<{ __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }> } | null, categories?: Array<{ __typename?: 'Category', id: string, name: string, rulesId: string, type: CategoryType, competitionEventIds: Array<any>, rankedResults: Array<{ __typename?: 'RankedResult', id: string, versionName?: string | null, versionType: ResultVersionType, competitionEventId: any, maxEntryLockedAt: number, results: Array<{ [prop: string]: any }> }>, pagePrintConfig: Array<{ __typename?: 'PagePrintConfig', competitionEventId?: any | null, zoom?: number | null, exclude?: boolean | null }>, participants: Array<{ __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }> }> } | null };

export type EntriesScoresheetsChangedSubscriptionVariables = Exact<{
  entryIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type EntriesScoresheetsChangedSubscription = { __typename?: 'Subscription', scoresheetChanged: string };

export const CategoryBaseFragmentDoc = gql`
    fragment CategoryBaseFragment on Category {
  id
  name
  rulesId
  type
}
    `;
export const CategoryPrintFragmentDoc = gql`
    fragment CategoryPrintFragment on Category {
  pagePrintConfig {
    competitionEventId
    zoom
    exclude
  }
}
    `;
export const AthleteFragmentDoc = gql`
    fragment AthleteFragment on Athlete {
  id
  name
  club
  country
  ijruId
}
    `;
export const TeamFragmentDoc = gql`
    fragment TeamFragment on Team {
  id
  name
  club
  country
  members
}
    `;
export const CategoryResultsFragmentDoc = gql`
    fragment CategoryResultsFragment on Category {
  competitionEventIds
  participants {
    ...AthleteFragment
    ...TeamFragment
  }
}
    ${AthleteFragmentDoc}
${TeamFragmentDoc}`;
export const DeviceBaseFragmentDoc = gql`
    fragment DeviceBaseFragment on Device {
  id
  name
  battery {
    automatic
    charging
    batteryLevel
    updatedAt
  }
}
    `;
export const EntryBaseFragmentDoc = gql`
    fragment EntryBaseFragment on Entry {
  id
  competitionEventId
  didNotSkipAt
  lockedAt
  heat
  pool
}
    `;
export const GroupBaseFragmentDoc = gql`
    fragment GroupBaseFragment on Group {
  id
  name
  resultVisibility
  createdAt
  completedAt
  currentHeat
}
    `;
export const JudgeAssignmentFragmentDoc = gql`
    fragment JudgeAssignmentFragment on JudgeAssignment {
  id
  competitionEventId
  judgeType
  pool
  options
}
    `;
export const JudgeBaseFragmentDoc = gql`
    fragment JudgeBaseFragment on Judge {
  id
  name
  ijruId
}
    `;
export const RankedResultBaseFragmentDoc = gql`
    fragment RankedResultBaseFragment on RankedResult {
  id
  versionName
  versionType
  competitionEventId
  maxEntryLockedAt
  results
}
    `;
export const ScoresheetBaseFragmentDoc = gql`
    fragment ScoresheetBaseFragment on Scoresheet {
  id
  judge {
    id
  }
  rulesId
  judgeType
  competitionEventId
  submitterProgramVersion
  createdAt
  updatedAt
  excludedAt
  options
}
    `;
export const MarkScoresheetStatusFragmentDoc = gql`
    fragment MarkScoresheetStatusFragment on MarkScoresheet {
  device {
    id
    name
  }
  completedAt
  submittedAt
  openedAt
}
    `;
export const MarkScoresheetFragmentDoc = gql`
    fragment MarkScoresheetFragment on MarkScoresheet {
  ...MarkScoresheetStatusFragment
  marks
}
    ${MarkScoresheetStatusFragmentDoc}`;
export const TallyScoresheetFragmentDoc = gql`
    fragment TallyScoresheetFragment on TallyScoresheet {
  tally
}
    `;
export const AddGroupAdminDocument = gql`
    mutation AddGroupAdmin($groupId: ID!, $userId: ID!) {
  addGroupAdmin(groupId: $groupId, userId: $userId) {
    id
    admins {
      id
    }
  }
}
    `;

/**
 * __useAddGroupAdminMutation__
 *
 * To run a mutation, you first call `useAddGroupAdminMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupAdminMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddGroupAdminMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     userId: // value for 'userId'
 *   },
 * });
 */
export function useAddGroupAdminMutation(options: VueApolloComposable.UseMutationOptions<AddGroupAdminMutation, AddGroupAdminMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddGroupAdminMutation, AddGroupAdminMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<AddGroupAdminMutation, AddGroupAdminMutationVariables>(AddGroupAdminDocument, options);
}
export type AddGroupAdminMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddGroupAdminMutation, AddGroupAdminMutationVariables>;
export const AddGroupViewerDocument = gql`
    mutation AddGroupViewer($groupId: ID!, $userId: ID!) {
  addGroupViewer(groupId: $groupId, userId: $userId) {
    id
    viewers {
      id
    }
  }
}
    `;

/**
 * __useAddGroupViewerMutation__
 *
 * To run a mutation, you first call `useAddGroupViewerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupViewerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddGroupViewerMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     userId: // value for 'userId'
 *   },
 * });
 */
export function useAddGroupViewerMutation(options: VueApolloComposable.UseMutationOptions<AddGroupViewerMutation, AddGroupViewerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddGroupViewerMutation, AddGroupViewerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<AddGroupViewerMutation, AddGroupViewerMutationVariables>(AddGroupViewerDocument, options);
}
export type AddGroupViewerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddGroupViewerMutation, AddGroupViewerMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($groupId: ID!, $data: CreateCategoryInput!) {
  createCategory(groupId: $groupId, data: $data) {
    ...CategoryBaseFragment
  }
}
    ${CategoryBaseFragmentDoc}`;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCategoryMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateCategoryMutation(options: VueApolloComposable.UseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
}
export type CreateCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreateEntryDocument = gql`
    mutation CreateEntry($categoryId: ID!, $participantId: ID!, $data: CreateEntryInput!) {
  createEntry(categoryId: $categoryId, participantId: $participantId, data: $data) {
    ...EntryBaseFragment
    participant {
      ... on Team {
        id
      }
      ... on Athlete {
        id
      }
    }
  }
}
    ${EntryBaseFragmentDoc}`;

/**
 * __useCreateEntryMutation__
 *
 * To run a mutation, you first call `useCreateEntryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateEntryMutation({
 *   variables: {
 *     categoryId: // value for 'categoryId'
 *     participantId: // value for 'participantId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateEntryMutation(options: VueApolloComposable.UseMutationOptions<CreateEntryMutation, CreateEntryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateEntryMutation, CreateEntryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateEntryMutation, CreateEntryMutationVariables>(CreateEntryDocument, options);
}
export type CreateEntryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateEntryMutation, CreateEntryMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($data: CreateGroupInput!) {
  createGroup(data: $data) {
    ...GroupBaseFragment
  }
}
    ${GroupBaseFragmentDoc}`;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateGroupMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateGroupMutation(options: VueApolloComposable.UseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
}
export type CreateGroupMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateGroupMutation, CreateGroupMutationVariables>;
export const CreateJudgeDocument = gql`
    mutation CreateJudge($groupId: ID!, $data: CreateJudgeInput!) {
  createJudge(groupId: $groupId, data: $data) {
    ...JudgeBaseFragment
  }
}
    ${JudgeBaseFragmentDoc}`;

/**
 * __useCreateJudgeMutation__
 *
 * To run a mutation, you first call `useCreateJudgeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateJudgeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateJudgeMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateJudgeMutation(options: VueApolloComposable.UseMutationOptions<CreateJudgeMutation, CreateJudgeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateJudgeMutation, CreateJudgeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateJudgeMutation, CreateJudgeMutationVariables>(CreateJudgeDocument, options);
}
export type CreateJudgeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateJudgeMutation, CreateJudgeMutationVariables>;
export const CreateJudgeAssignmentDocument = gql`
    mutation CreateJudgeAssignment($categoryId: ID!, $judgeId: ID!, $data: CreateJudgeAssignmentInput!) {
  createJudgeAssignment(categoryId: $categoryId, judgeId: $judgeId, data: $data) {
    ...JudgeAssignmentFragment
  }
}
    ${JudgeAssignmentFragmentDoc}`;

/**
 * __useCreateJudgeAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateJudgeAssignmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateJudgeAssignmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateJudgeAssignmentMutation({
 *   variables: {
 *     categoryId: // value for 'categoryId'
 *     judgeId: // value for 'judgeId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateJudgeAssignmentMutation(options: VueApolloComposable.UseMutationOptions<CreateJudgeAssignmentMutation, CreateJudgeAssignmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateJudgeAssignmentMutation, CreateJudgeAssignmentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateJudgeAssignmentMutation, CreateJudgeAssignmentMutationVariables>(CreateJudgeAssignmentDocument, options);
}
export type CreateJudgeAssignmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateJudgeAssignmentMutation, CreateJudgeAssignmentMutationVariables>;
export const CreateAthleteDocument = gql`
    mutation CreateAthlete($categoryId: ID!, $data: CreateAthleteInput!) {
  createAthlete(categoryId: $categoryId, data: $data) {
    ...AthleteFragment
  }
}
    ${AthleteFragmentDoc}`;

/**
 * __useCreateAthleteMutation__
 *
 * To run a mutation, you first call `useCreateAthleteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAthleteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAthleteMutation({
 *   variables: {
 *     categoryId: // value for 'categoryId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateAthleteMutation(options: VueApolloComposable.UseMutationOptions<CreateAthleteMutation, CreateAthleteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAthleteMutation, CreateAthleteMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateAthleteMutation, CreateAthleteMutationVariables>(CreateAthleteDocument, options);
}
export type CreateAthleteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateAthleteMutation, CreateAthleteMutationVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($categoryId: ID!, $data: CreateTeamInput!) {
  createTeam(categoryId: $categoryId, data: $data) {
    ...TeamFragment
  }
}
    ${TeamFragmentDoc}`;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTeamMutation({
 *   variables: {
 *     categoryId: // value for 'categoryId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateTeamMutation(options: VueApolloComposable.UseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
}
export type CreateTeamMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateTeamMutation, CreateTeamMutationVariables>;
export const CreateTallyScoresheetDocument = gql`
    mutation CreateTallyScoresheet($entryId: ID!, $judgeId: ID!, $data: CreateTallyScoresheetInput!) {
  createTallyScoresheet(entryId: $entryId, judgeId: $judgeId, data: $data) {
    ...ScoresheetBaseFragment
    ...TallyScoresheetFragment
  }
}
    ${ScoresheetBaseFragmentDoc}
${TallyScoresheetFragmentDoc}`;

/**
 * __useCreateTallyScoresheetMutation__
 *
 * To run a mutation, you first call `useCreateTallyScoresheetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTallyScoresheetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTallyScoresheetMutation({
 *   variables: {
 *     entryId: // value for 'entryId'
 *     judgeId: // value for 'judgeId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateTallyScoresheetMutation(options: VueApolloComposable.UseMutationOptions<CreateTallyScoresheetMutation, CreateTallyScoresheetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateTallyScoresheetMutation, CreateTallyScoresheetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateTallyScoresheetMutation, CreateTallyScoresheetMutationVariables>(CreateTallyScoresheetDocument, options);
}
export type CreateTallyScoresheetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateTallyScoresheetMutation, CreateTallyScoresheetMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($categoryId: ID!) {
  deleteCategory(categoryId: $categoryId) {
    id
  }
}
    `;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteCategoryMutation({
 *   variables: {
 *     categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteCategoryMutation(options: VueApolloComposable.UseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
}
export type DeleteCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const DeleteJudgeAssignmentDocument = gql`
    mutation DeleteJudgeAssignment($judgeAssignmentId: ID!) {
  deleteJudgeAssignment(judgeAssignmentId: $judgeAssignmentId) {
    id
  }
}
    `;

/**
 * __useDeleteJudgeAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteJudgeAssignmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJudgeAssignmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteJudgeAssignmentMutation({
 *   variables: {
 *     judgeAssignmentId: // value for 'judgeAssignmentId'
 *   },
 * });
 */
export function useDeleteJudgeAssignmentMutation(options: VueApolloComposable.UseMutationOptions<DeleteJudgeAssignmentMutation, DeleteJudgeAssignmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteJudgeAssignmentMutation, DeleteJudgeAssignmentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteJudgeAssignmentMutation, DeleteJudgeAssignmentMutationVariables>(DeleteJudgeAssignmentDocument, options);
}
export type DeleteJudgeAssignmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteJudgeAssignmentMutation, DeleteJudgeAssignmentMutationVariables>;
export const DeleteParticipantDocument = gql`
    mutation DeleteParticipant($participantId: ID!) {
  deleteParticipant(participantId: $participantId) {
    ... on Team {
      id
    }
    ... on Athlete {
      id
    }
  }
}
    `;

/**
 * __useDeleteParticipantMutation__
 *
 * To run a mutation, you first call `useDeleteParticipantMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParticipantMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteParticipantMutation({
 *   variables: {
 *     participantId: // value for 'participantId'
 *   },
 * });
 */
export function useDeleteParticipantMutation(options: VueApolloComposable.UseMutationOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteParticipantMutation, DeleteParticipantMutationVariables>(DeleteParticipantDocument, options);
}
export type DeleteParticipantMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteParticipantMutation, DeleteParticipantMutationVariables>;
export const FillTallyScoresheetDocument = gql`
    mutation FillTallyScoresheet($scoresheetId: ID!, $tally: JSONObject!, $programVersion: String) {
  fillTallyScoresheet(
    scoresheetId: $scoresheetId
    tally: $tally
    programVersion: $programVersion
  ) {
    ...ScoresheetBaseFragment
    ...TallyScoresheetFragment
  }
}
    ${ScoresheetBaseFragmentDoc}
${TallyScoresheetFragmentDoc}`;

/**
 * __useFillTallyScoresheetMutation__
 *
 * To run a mutation, you first call `useFillTallyScoresheetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useFillTallyScoresheetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useFillTallyScoresheetMutation({
 *   variables: {
 *     scoresheetId: // value for 'scoresheetId'
 *     tally: // value for 'tally'
 *     programVersion: // value for 'programVersion'
 *   },
 * });
 */
export function useFillTallyScoresheetMutation(options: VueApolloComposable.UseMutationOptions<FillTallyScoresheetMutation, FillTallyScoresheetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<FillTallyScoresheetMutation, FillTallyScoresheetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<FillTallyScoresheetMutation, FillTallyScoresheetMutationVariables>(FillTallyScoresheetDocument, options);
}
export type FillTallyScoresheetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<FillTallyScoresheetMutation, FillTallyScoresheetMutationVariables>;
export const ReleaseRankedResultVersionDocument = gql`
    mutation ReleaseRankedResultVersion($resultId: ID!) {
  releaseRankedResultVersion(resultId: $resultId) {
    ...RankedResultBaseFragment
  }
}
    ${RankedResultBaseFragmentDoc}`;

/**
 * __useReleaseRankedResultVersionMutation__
 *
 * To run a mutation, you first call `useReleaseRankedResultVersionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReleaseRankedResultVersionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReleaseRankedResultVersionMutation({
 *   variables: {
 *     resultId: // value for 'resultId'
 *   },
 * });
 */
export function useReleaseRankedResultVersionMutation(options: VueApolloComposable.UseMutationOptions<ReleaseRankedResultVersionMutation, ReleaseRankedResultVersionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ReleaseRankedResultVersionMutation, ReleaseRankedResultVersionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ReleaseRankedResultVersionMutation, ReleaseRankedResultVersionMutationVariables>(ReleaseRankedResultVersionDocument, options);
}
export type ReleaseRankedResultVersionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ReleaseRankedResultVersionMutation, ReleaseRankedResultVersionMutationVariables>;
export const RemoveGroupAdminDocument = gql`
    mutation RemoveGroupAdmin($groupId: ID!, $userId: ID!) {
  removeGroupAdmin(groupId: $groupId, userId: $userId) {
    id
    admins {
      id
    }
  }
}
    `;

/**
 * __useRemoveGroupAdminMutation__
 *
 * To run a mutation, you first call `useRemoveGroupAdminMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupAdminMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveGroupAdminMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveGroupAdminMutation(options: VueApolloComposable.UseMutationOptions<RemoveGroupAdminMutation, RemoveGroupAdminMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveGroupAdminMutation, RemoveGroupAdminMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveGroupAdminMutation, RemoveGroupAdminMutationVariables>(RemoveGroupAdminDocument, options);
}
export type RemoveGroupAdminMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveGroupAdminMutation, RemoveGroupAdminMutationVariables>;
export const RemoveGroupViewerDocument = gql`
    mutation RemoveGroupViewer($groupId: ID!, $userId: ID!) {
  removeGroupViewer(groupId: $groupId, userId: $userId) {
    id
    viewers {
      id
    }
  }
}
    `;

/**
 * __useRemoveGroupViewerMutation__
 *
 * To run a mutation, you first call `useRemoveGroupViewerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupViewerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveGroupViewerMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveGroupViewerMutation(options: VueApolloComposable.UseMutationOptions<RemoveGroupViewerMutation, RemoveGroupViewerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveGroupViewerMutation, RemoveGroupViewerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveGroupViewerMutation, RemoveGroupViewerMutationVariables>(RemoveGroupViewerDocument, options);
}
export type RemoveGroupViewerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveGroupViewerMutation, RemoveGroupViewerMutationVariables>;
export const ReorderEntryDocument = gql`
    mutation ReorderEntry($entryId: ID!, $heat: Int, $pool: Int) {
  reorderEntry(entryId: $entryId, heat: $heat, pool: $pool) {
    id
    heat
    pool
  }
}
    `;

/**
 * __useReorderEntryMutation__
 *
 * To run a mutation, you first call `useReorderEntryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReorderEntryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReorderEntryMutation({
 *   variables: {
 *     entryId: // value for 'entryId'
 *     heat: // value for 'heat'
 *     pool: // value for 'pool'
 *   },
 * });
 */
export function useReorderEntryMutation(options: VueApolloComposable.UseMutationOptions<ReorderEntryMutation, ReorderEntryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ReorderEntryMutation, ReorderEntryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ReorderEntryMutation, ReorderEntryMutationVariables>(ReorderEntryDocument, options);
}
export type ReorderEntryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ReorderEntryMutation, ReorderEntryMutationVariables>;
export const SetCurrentHeatDocument = gql`
    mutation SetCurrentHeat($groupId: ID!, $heat: Int!) {
  setCurrentHeat(groupId: $groupId, heat: $heat) {
    id
    currentHeat
  }
}
    `;

/**
 * __useSetCurrentHeatMutation__
 *
 * To run a mutation, you first call `useSetCurrentHeatMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentHeatMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetCurrentHeatMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     heat: // value for 'heat'
 *   },
 * });
 */
export function useSetCurrentHeatMutation(options: VueApolloComposable.UseMutationOptions<SetCurrentHeatMutation, SetCurrentHeatMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SetCurrentHeatMutation, SetCurrentHeatMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SetCurrentHeatMutation, SetCurrentHeatMutationVariables>(SetCurrentHeatDocument, options);
}
export type SetCurrentHeatMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SetCurrentHeatMutation, SetCurrentHeatMutationVariables>;
export const SetJudgeDeviceDocument = gql`
    mutation SetJudgeDevice($judgeId: ID!, $deviceId: ID!) {
  setJudgeDevice(judgeId: $judgeId, deviceId: $deviceId) {
    id
    device {
      ...DeviceBaseFragment
    }
  }
}
    ${DeviceBaseFragmentDoc}`;

/**
 * __useSetJudgeDeviceMutation__
 *
 * To run a mutation, you first call `useSetJudgeDeviceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetJudgeDeviceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetJudgeDeviceMutation({
 *   variables: {
 *     judgeId: // value for 'judgeId'
 *     deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useSetJudgeDeviceMutation(options: VueApolloComposable.UseMutationOptions<SetJudgeDeviceMutation, SetJudgeDeviceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SetJudgeDeviceMutation, SetJudgeDeviceMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SetJudgeDeviceMutation, SetJudgeDeviceMutationVariables>(SetJudgeDeviceDocument, options);
}
export type SetJudgeDeviceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SetJudgeDeviceMutation, SetJudgeDeviceMutationVariables>;
export const SetPagePrintConfigDocument = gql`
    mutation SetPagePrintConfig($categoryId: ID!, $competitionEventId: CompetitionEventLookupCode!, $data: SetPagePrintConfigInput!) {
  setPagePrintConfig(
    categoryId: $categoryId
    competitionEventId: $competitionEventId
    data: $data
  ) {
    id
    ...CategoryPrintFragment
  }
}
    ${CategoryPrintFragmentDoc}`;

/**
 * __useSetPagePrintConfigMutation__
 *
 * To run a mutation, you first call `useSetPagePrintConfigMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetPagePrintConfigMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetPagePrintConfigMutation({
 *   variables: {
 *     categoryId: // value for 'categoryId'
 *     competitionEventId: // value for 'competitionEventId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useSetPagePrintConfigMutation(options: VueApolloComposable.UseMutationOptions<SetPagePrintConfigMutation, SetPagePrintConfigMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SetPagePrintConfigMutation, SetPagePrintConfigMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SetPagePrintConfigMutation, SetPagePrintConfigMutationVariables>(SetPagePrintConfigDocument, options);
}
export type SetPagePrintConfigMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SetPagePrintConfigMutation, SetPagePrintConfigMutationVariables>;
export const SetRankedResultVersionDocument = gql`
    mutation SetRankedResultVersion($resultId: ID!, $type: ResultVersionType!, $name: String!) {
  setRankedResultVersion(resultId: $resultId, type: $type, name: $name) {
    ...RankedResultBaseFragment
  }
}
    ${RankedResultBaseFragmentDoc}`;

/**
 * __useSetRankedResultVersionMutation__
 *
 * To run a mutation, you first call `useSetRankedResultVersionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetRankedResultVersionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetRankedResultVersionMutation({
 *   variables: {
 *     resultId: // value for 'resultId'
 *     type: // value for 'type'
 *     name: // value for 'name'
 *   },
 * });
 */
export function useSetRankedResultVersionMutation(options: VueApolloComposable.UseMutationOptions<SetRankedResultVersionMutation, SetRankedResultVersionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SetRankedResultVersionMutation, SetRankedResultVersionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SetRankedResultVersionMutation, SetRankedResultVersionMutationVariables>(SetRankedResultVersionDocument, options);
}
export type SetRankedResultVersionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SetRankedResultVersionMutation, SetRankedResultVersionMutationVariables>;
export const SetScoresheetExclusionDocument = gql`
    mutation SetScoresheetExclusion($scoresheetId: ID!, $exclude: Boolean!) {
  setScoresheetExclusion(scoresheetId: $scoresheetId, exclude: $exclude) {
    id
    excludedAt
  }
}
    `;

/**
 * __useSetScoresheetExclusionMutation__
 *
 * To run a mutation, you first call `useSetScoresheetExclusionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetScoresheetExclusionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetScoresheetExclusionMutation({
 *   variables: {
 *     scoresheetId: // value for 'scoresheetId'
 *     exclude: // value for 'exclude'
 *   },
 * });
 */
export function useSetScoresheetExclusionMutation(options: VueApolloComposable.UseMutationOptions<SetScoresheetExclusionMutation, SetScoresheetExclusionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SetScoresheetExclusionMutation, SetScoresheetExclusionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SetScoresheetExclusionMutation, SetScoresheetExclusionMutationVariables>(SetScoresheetExclusionDocument, options);
}
export type SetScoresheetExclusionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SetScoresheetExclusionMutation, SetScoresheetExclusionMutationVariables>;
export const SetScoresheetOptionsDocument = gql`
    mutation SetScoresheetOptions($scoresheetId: ID!, $options: JSONObject!) {
  setScoresheetOptions(scoresheetId: $scoresheetId, options: $options) {
    id
    options
  }
}
    `;

/**
 * __useSetScoresheetOptionsMutation__
 *
 * To run a mutation, you first call `useSetScoresheetOptionsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetScoresheetOptionsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetScoresheetOptionsMutation({
 *   variables: {
 *     scoresheetId: // value for 'scoresheetId'
 *     options: // value for 'options'
 *   },
 * });
 */
export function useSetScoresheetOptionsMutation(options: VueApolloComposable.UseMutationOptions<SetScoresheetOptionsMutation, SetScoresheetOptionsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SetScoresheetOptionsMutation, SetScoresheetOptionsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SetScoresheetOptionsMutation, SetScoresheetOptionsMutationVariables>(SetScoresheetOptionsDocument, options);
}
export type SetScoresheetOptionsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SetScoresheetOptionsMutation, SetScoresheetOptionsMutationVariables>;
export const ToggleEntryLockDocument = gql`
    mutation ToggleEntryLock($entryId: ID!, $lock: Boolean!, $didNotSkip: Boolean!) {
  toggleEntryLock(entryId: $entryId, lock: $lock, didNotSkip: $didNotSkip) {
    ...EntryBaseFragment
  }
}
    ${EntryBaseFragmentDoc}`;

/**
 * __useToggleEntryLockMutation__
 *
 * To run a mutation, you first call `useToggleEntryLockMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useToggleEntryLockMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useToggleEntryLockMutation({
 *   variables: {
 *     entryId: // value for 'entryId'
 *     lock: // value for 'lock'
 *     didNotSkip: // value for 'didNotSkip'
 *   },
 * });
 */
export function useToggleEntryLockMutation(options: VueApolloComposable.UseMutationOptions<ToggleEntryLockMutation, ToggleEntryLockMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ToggleEntryLockMutation, ToggleEntryLockMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ToggleEntryLockMutation, ToggleEntryLockMutationVariables>(ToggleEntryLockDocument, options);
}
export type ToggleEntryLockMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ToggleEntryLockMutation, ToggleEntryLockMutationVariables>;
export const ToggleGroupCompleteDocument = gql`
    mutation ToggleGroupComplete($groupId: ID!, $completed: Boolean!) {
  toggleGroupComplete(groupId: $groupId, completed: $completed) {
    ...GroupBaseFragment
  }
}
    ${GroupBaseFragmentDoc}`;

/**
 * __useToggleGroupCompleteMutation__
 *
 * To run a mutation, you first call `useToggleGroupCompleteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useToggleGroupCompleteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useToggleGroupCompleteMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     completed: // value for 'completed'
 *   },
 * });
 */
export function useToggleGroupCompleteMutation(options: VueApolloComposable.UseMutationOptions<ToggleGroupCompleteMutation, ToggleGroupCompleteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ToggleGroupCompleteMutation, ToggleGroupCompleteMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ToggleGroupCompleteMutation, ToggleGroupCompleteMutationVariables>(ToggleGroupCompleteDocument, options);
}
export type ToggleGroupCompleteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ToggleGroupCompleteMutation, ToggleGroupCompleteMutationVariables>;
export const UnsetJudgeDeviceDocument = gql`
    mutation UnsetJudgeDevice($judgeId: ID!) {
  unsetJudgeDevice(judgeId: $judgeId) {
    id
    device {
      ...DeviceBaseFragment
    }
  }
}
    ${DeviceBaseFragmentDoc}`;

/**
 * __useUnsetJudgeDeviceMutation__
 *
 * To run a mutation, you first call `useUnsetJudgeDeviceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUnsetJudgeDeviceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUnsetJudgeDeviceMutation({
 *   variables: {
 *     judgeId: // value for 'judgeId'
 *   },
 * });
 */
export function useUnsetJudgeDeviceMutation(options: VueApolloComposable.UseMutationOptions<UnsetJudgeDeviceMutation, UnsetJudgeDeviceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UnsetJudgeDeviceMutation, UnsetJudgeDeviceMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UnsetJudgeDeviceMutation, UnsetJudgeDeviceMutationVariables>(UnsetJudgeDeviceDocument, options);
}
export type UnsetJudgeDeviceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UnsetJudgeDeviceMutation, UnsetJudgeDeviceMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($categoryId: ID!, $data: UpdateCategoryInput!) {
  updateCategory(categoryId: $categoryId, data: $data) {
    ...CategoryBaseFragment
    competitionEventIds
  }
}
    ${CategoryBaseFragmentDoc}`;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCategoryMutation({
 *   variables: {
 *     categoryId: // value for 'categoryId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCategoryMutation(options: VueApolloComposable.UseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
}
export type UpdateCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($groupId: ID!, $data: UpdateGroupInput!) {
  updateGroup(groupId: $groupId, data: $data) {
    ...GroupBaseFragment
  }
}
    ${GroupBaseFragmentDoc}`;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateGroupMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateGroupMutation(options: VueApolloComposable.UseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
}
export type UpdateGroupMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const UpdateJudgeDocument = gql`
    mutation UpdateJudge($judgeId: ID!, $data: UpdateJudgeInput!) {
  updateJudge(judgeId: $judgeId, data: $data) {
    ...JudgeBaseFragment
  }
}
    ${JudgeBaseFragmentDoc}`;

/**
 * __useUpdateJudgeMutation__
 *
 * To run a mutation, you first call `useUpdateJudgeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJudgeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateJudgeMutation({
 *   variables: {
 *     judgeId: // value for 'judgeId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateJudgeMutation(options: VueApolloComposable.UseMutationOptions<UpdateJudgeMutation, UpdateJudgeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateJudgeMutation, UpdateJudgeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateJudgeMutation, UpdateJudgeMutationVariables>(UpdateJudgeDocument, options);
}
export type UpdateJudgeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateJudgeMutation, UpdateJudgeMutationVariables>;
export const UpdateJudgeAssignmentDocument = gql`
    mutation UpdateJudgeAssignment($judgeAssignmentId: ID!, $data: UpdateJudgeAssignmentInput!) {
  updateJudgeAssignment(judgeAssignmentId: $judgeAssignmentId, data: $data) {
    ...JudgeAssignmentFragment
  }
}
    ${JudgeAssignmentFragmentDoc}`;

/**
 * __useUpdateJudgeAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateJudgeAssignmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJudgeAssignmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateJudgeAssignmentMutation({
 *   variables: {
 *     judgeAssignmentId: // value for 'judgeAssignmentId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateJudgeAssignmentMutation(options: VueApolloComposable.UseMutationOptions<UpdateJudgeAssignmentMutation, UpdateJudgeAssignmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateJudgeAssignmentMutation, UpdateJudgeAssignmentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateJudgeAssignmentMutation, UpdateJudgeAssignmentMutationVariables>(UpdateJudgeAssignmentDocument, options);
}
export type UpdateJudgeAssignmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateJudgeAssignmentMutation, UpdateJudgeAssignmentMutationVariables>;
export const UpdateAthleteDocument = gql`
    mutation UpdateAthlete($participantId: ID!, $data: UpdateAthleteInput!) {
  updateAthlete(participantId: $participantId, data: $data) {
    ...AthleteFragment
  }
}
    ${AthleteFragmentDoc}`;

/**
 * __useUpdateAthleteMutation__
 *
 * To run a mutation, you first call `useUpdateAthleteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAthleteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAthleteMutation({
 *   variables: {
 *     participantId: // value for 'participantId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAthleteMutation(options: VueApolloComposable.UseMutationOptions<UpdateAthleteMutation, UpdateAthleteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateAthleteMutation, UpdateAthleteMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateAthleteMutation, UpdateAthleteMutationVariables>(UpdateAthleteDocument, options);
}
export type UpdateAthleteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateAthleteMutation, UpdateAthleteMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($participantId: ID!, $data: UpdateTeamInput!) {
  updateTeam(participantId: $participantId, data: $data) {
    ...TeamFragment
  }
}
    ${TeamFragmentDoc}`;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateTeamMutation({
 *   variables: {
 *     participantId: // value for 'participantId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTeamMutation(options: VueApolloComposable.UseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
}
export type UpdateTeamMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($name: String) {
  updateUser(name: $name) {
    id
    name
  }
}
    `;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserMutation({
 *   variables: {
 *     name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserMutation(options: VueApolloComposable.UseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateUserMutation, UpdateUserMutationVariables>;
export const CategoryGridDocument = gql`
    query CategoryGrid($groupId: ID!, $categoryId: ID!) {
  group(groupId: $groupId) {
    id
    category(categoryId: $categoryId) {
      ...CategoryBaseFragment
      competitionEventIds
      participants {
        ...AthleteFragment
        ...TeamFragment
      }
      entries {
        ...EntryBaseFragment
        participant {
          ... on Athlete {
            id
          }
          ... on Team {
            id
          }
        }
      }
    }
  }
}
    ${CategoryBaseFragmentDoc}
${AthleteFragmentDoc}
${TeamFragmentDoc}
${EntryBaseFragmentDoc}`;

/**
 * __useCategoryGridQuery__
 *
 * To run a query within a Vue component, call `useCategoryGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryGridQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCategoryGridQuery({
 *   groupId: // value for 'groupId'
 *   categoryId: // value for 'categoryId'
 * });
 */
export function useCategoryGridQuery(variables: CategoryGridQueryVariables | VueCompositionApi.Ref<CategoryGridQueryVariables> | ReactiveFunction<CategoryGridQueryVariables>, options: VueApolloComposable.UseQueryOptions<CategoryGridQuery, CategoryGridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoryGridQuery, CategoryGridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoryGridQuery, CategoryGridQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CategoryGridQuery, CategoryGridQueryVariables>(CategoryGridDocument, variables, options);
}
export function useCategoryGridLazyQuery(variables?: CategoryGridQueryVariables | VueCompositionApi.Ref<CategoryGridQueryVariables> | ReactiveFunction<CategoryGridQueryVariables>, options: VueApolloComposable.UseQueryOptions<CategoryGridQuery, CategoryGridQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoryGridQuery, CategoryGridQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoryGridQuery, CategoryGridQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CategoryGridQuery, CategoryGridQueryVariables>(CategoryGridDocument, variables, options);
}
export type CategoryGridQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CategoryGridQuery, CategoryGridQueryVariables>;
export const CategorySettingsDocument = gql`
    query CategorySettings($groupId: ID!, $categoryId: ID!) {
  group(groupId: $groupId) {
    id
    judges {
      ...JudgeBaseFragment
      assignments(categoryId: $categoryId) {
        ...JudgeAssignmentFragment
      }
    }
    category(categoryId: $categoryId) {
      ...CategoryBaseFragment
      competitionEventIds
      participants {
        ...AthleteFragment
        ...TeamFragment
      }
    }
  }
}
    ${JudgeBaseFragmentDoc}
${JudgeAssignmentFragmentDoc}
${CategoryBaseFragmentDoc}
${AthleteFragmentDoc}
${TeamFragmentDoc}`;

/**
 * __useCategorySettingsQuery__
 *
 * To run a query within a Vue component, call `useCategorySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategorySettingsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCategorySettingsQuery({
 *   groupId: // value for 'groupId'
 *   categoryId: // value for 'categoryId'
 * });
 */
export function useCategorySettingsQuery(variables: CategorySettingsQueryVariables | VueCompositionApi.Ref<CategorySettingsQueryVariables> | ReactiveFunction<CategorySettingsQueryVariables>, options: VueApolloComposable.UseQueryOptions<CategorySettingsQuery, CategorySettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategorySettingsQuery, CategorySettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategorySettingsQuery, CategorySettingsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CategorySettingsQuery, CategorySettingsQueryVariables>(CategorySettingsDocument, variables, options);
}
export function useCategorySettingsLazyQuery(variables?: CategorySettingsQueryVariables | VueCompositionApi.Ref<CategorySettingsQueryVariables> | ReactiveFunction<CategorySettingsQueryVariables>, options: VueApolloComposable.UseQueryOptions<CategorySettingsQuery, CategorySettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategorySettingsQuery, CategorySettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategorySettingsQuery, CategorySettingsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CategorySettingsQuery, CategorySettingsQueryVariables>(CategorySettingsDocument, variables, options);
}
export type CategorySettingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CategorySettingsQuery, CategorySettingsQueryVariables>;
export const CurrentHeatScoresheetsDocument = gql`
    query CurrentHeatScoresheets($groupId: ID!, $heat: Int!) {
  group(groupId: $groupId) {
    id
    entriesByHeat(heat: $heat) {
      ...EntryBaseFragment
      scoresheets {
        ...ScoresheetBaseFragment
        ...MarkScoresheetStatusFragment
      }
    }
  }
}
    ${EntryBaseFragmentDoc}
${ScoresheetBaseFragmentDoc}
${MarkScoresheetStatusFragmentDoc}`;

/**
 * __useCurrentHeatScoresheetsQuery__
 *
 * To run a query within a Vue component, call `useCurrentHeatScoresheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentHeatScoresheetsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCurrentHeatScoresheetsQuery({
 *   groupId: // value for 'groupId'
 *   heat: // value for 'heat'
 * });
 */
export function useCurrentHeatScoresheetsQuery(variables: CurrentHeatScoresheetsQueryVariables | VueCompositionApi.Ref<CurrentHeatScoresheetsQueryVariables> | ReactiveFunction<CurrentHeatScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables>(CurrentHeatScoresheetsDocument, variables, options);
}
export function useCurrentHeatScoresheetsLazyQuery(variables?: CurrentHeatScoresheetsQueryVariables | VueCompositionApi.Ref<CurrentHeatScoresheetsQueryVariables> | ReactiveFunction<CurrentHeatScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables>(CurrentHeatScoresheetsDocument, variables, options);
}
export type CurrentHeatScoresheetsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CurrentHeatScoresheetsQuery, CurrentHeatScoresheetsQueryVariables>;
export const EntriesWithScoresheetsDocument = gql`
    query EntriesWithScoresheets($groupId: ID!, $categoryId: ID!, $competitionEventId: CompetitionEventLookupCode!) {
  group(groupId: $groupId) {
    id
    category(categoryId: $categoryId) {
      ...CategoryBaseFragment
      judgeAssignments {
        ...JudgeAssignmentFragment
        judge {
          ...JudgeBaseFragment
        }
      }
      participants {
        ...AthleteFragment
        ...TeamFragment
      }
      entries(competitionEventId: $competitionEventId) {
        ...EntryBaseFragment
        participant {
          ... on Athlete {
            id
          }
          ... on Team {
            id
          }
        }
        scoresheets {
          ...ScoresheetBaseFragment
          ...MarkScoresheetFragment
          ...TallyScoresheetFragment
        }
      }
    }
  }
}
    ${CategoryBaseFragmentDoc}
${JudgeAssignmentFragmentDoc}
${JudgeBaseFragmentDoc}
${AthleteFragmentDoc}
${TeamFragmentDoc}
${EntryBaseFragmentDoc}
${ScoresheetBaseFragmentDoc}
${MarkScoresheetFragmentDoc}
${TallyScoresheetFragmentDoc}`;

/**
 * __useEntriesWithScoresheetsQuery__
 *
 * To run a query within a Vue component, call `useEntriesWithScoresheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntriesWithScoresheetsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEntriesWithScoresheetsQuery({
 *   groupId: // value for 'groupId'
 *   categoryId: // value for 'categoryId'
 *   competitionEventId: // value for 'competitionEventId'
 * });
 */
export function useEntriesWithScoresheetsQuery(variables: EntriesWithScoresheetsQueryVariables | VueCompositionApi.Ref<EntriesWithScoresheetsQueryVariables> | ReactiveFunction<EntriesWithScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables>(EntriesWithScoresheetsDocument, variables, options);
}
export function useEntriesWithScoresheetsLazyQuery(variables?: EntriesWithScoresheetsQueryVariables | VueCompositionApi.Ref<EntriesWithScoresheetsQueryVariables> | ReactiveFunction<EntriesWithScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables>(EntriesWithScoresheetsDocument, variables, options);
}
export type EntriesWithScoresheetsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EntriesWithScoresheetsQuery, EntriesWithScoresheetsQueryVariables>;
export const EntryWithScoresheetsDocument = gql`
    query EntryWithScoresheets($groupId: ID!, $categoryId: ID!, $entryId: ID!) {
  group(groupId: $groupId) {
    id
    category(categoryId: $categoryId) {
      ...CategoryBaseFragment
      judgeAssignments {
        ...JudgeAssignmentFragment
        judge {
          ...JudgeBaseFragment
        }
      }
    }
    entry(entryId: $entryId) {
      ...EntryBaseFragment
      participant {
        ...AthleteFragment
        ...TeamFragment
      }
      scoresheets {
        ...ScoresheetBaseFragment
        ...MarkScoresheetFragment
        ...TallyScoresheetFragment
      }
    }
  }
}
    ${CategoryBaseFragmentDoc}
${JudgeAssignmentFragmentDoc}
${JudgeBaseFragmentDoc}
${EntryBaseFragmentDoc}
${AthleteFragmentDoc}
${TeamFragmentDoc}
${ScoresheetBaseFragmentDoc}
${MarkScoresheetFragmentDoc}
${TallyScoresheetFragmentDoc}`;

/**
 * __useEntryWithScoresheetsQuery__
 *
 * To run a query within a Vue component, call `useEntryWithScoresheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryWithScoresheetsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEntryWithScoresheetsQuery({
 *   groupId: // value for 'groupId'
 *   categoryId: // value for 'categoryId'
 *   entryId: // value for 'entryId'
 * });
 */
export function useEntryWithScoresheetsQuery(variables: EntryWithScoresheetsQueryVariables | VueCompositionApi.Ref<EntryWithScoresheetsQueryVariables> | ReactiveFunction<EntryWithScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables>(EntryWithScoresheetsDocument, variables, options);
}
export function useEntryWithScoresheetsLazyQuery(variables?: EntryWithScoresheetsQueryVariables | VueCompositionApi.Ref<EntryWithScoresheetsQueryVariables> | ReactiveFunction<EntryWithScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables>(EntryWithScoresheetsDocument, variables, options);
}
export type EntryWithScoresheetsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EntryWithScoresheetsQuery, EntryWithScoresheetsQueryVariables>;
export const GroupInfoDocument = gql`
    query GroupInfo($groupId: ID!) {
  group(groupId: $groupId) {
    ...GroupBaseFragment
    admins {
      id
      name
    }
    viewers {
      id
      name
    }
  }
}
    ${GroupBaseFragmentDoc}`;

/**
 * __useGroupInfoQuery__
 *
 * To run a query within a Vue component, call `useGroupInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupInfoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGroupInfoQuery({
 *   groupId: // value for 'groupId'
 * });
 */
export function useGroupInfoQuery(variables: GroupInfoQueryVariables | VueCompositionApi.Ref<GroupInfoQueryVariables> | ReactiveFunction<GroupInfoQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GroupInfoQuery, GroupInfoQueryVariables>(GroupInfoDocument, variables, options);
}
export function useGroupInfoLazyQuery(variables?: GroupInfoQueryVariables | VueCompositionApi.Ref<GroupInfoQueryVariables> | ReactiveFunction<GroupInfoQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GroupInfoQuery, GroupInfoQueryVariables>(GroupInfoDocument, variables, options);
}
export type GroupInfoQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GroupInfoQuery, GroupInfoQueryVariables>;
export const GroupsDocument = gql`
    query Groups {
  groups {
    ...GroupBaseFragment
    categories {
      ...CategoryBaseFragment
    }
  }
}
    ${GroupBaseFragmentDoc}
${CategoryBaseFragmentDoc}`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a Vue component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGroupsQuery();
 */
export function useGroupsQuery(options: VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, {}, options);
}
export function useGroupsLazyQuery(options: VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, {}, options);
}
export type GroupsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GroupsQuery, GroupsQueryVariables>;
export const HeatsDocument = gql`
    query Heats($groupId: ID!) {
  group(groupId: $groupId) {
    ...GroupBaseFragment
    categories {
      ...CategoryBaseFragment
      judgeAssignments {
        ...JudgeAssignmentFragment
        judge {
          ...JudgeBaseFragment
        }
      }
    }
    entries {
      ...EntryBaseFragment
      category {
        id
      }
      participant {
        ...AthleteFragment
        ...TeamFragment
      }
      scoresheets {
        ...ScoresheetBaseFragment
        ...MarkScoresheetStatusFragment
      }
    }
  }
}
    ${GroupBaseFragmentDoc}
${CategoryBaseFragmentDoc}
${JudgeAssignmentFragmentDoc}
${JudgeBaseFragmentDoc}
${EntryBaseFragmentDoc}
${AthleteFragmentDoc}
${TeamFragmentDoc}
${ScoresheetBaseFragmentDoc}
${MarkScoresheetStatusFragmentDoc}`;

/**
 * __useHeatsQuery__
 *
 * To run a query within a Vue component, call `useHeatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useHeatsQuery({
 *   groupId: // value for 'groupId'
 * });
 */
export function useHeatsQuery(variables: HeatsQueryVariables | VueCompositionApi.Ref<HeatsQueryVariables> | ReactiveFunction<HeatsQueryVariables>, options: VueApolloComposable.UseQueryOptions<HeatsQuery, HeatsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HeatsQuery, HeatsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HeatsQuery, HeatsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<HeatsQuery, HeatsQueryVariables>(HeatsDocument, variables, options);
}
export function useHeatsLazyQuery(variables?: HeatsQueryVariables | VueCompositionApi.Ref<HeatsQueryVariables> | ReactiveFunction<HeatsQueryVariables>, options: VueApolloComposable.UseQueryOptions<HeatsQuery, HeatsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HeatsQuery, HeatsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HeatsQuery, HeatsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<HeatsQuery, HeatsQueryVariables>(HeatsDocument, variables, options);
}
export type HeatsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<HeatsQuery, HeatsQueryVariables>;
export const JudgeStatusesDocument = gql`
    query JudgeStatuses($groupId: ID!) {
  group(groupId: $groupId) {
    id
    judges {
      ...JudgeBaseFragment
      device {
        ...DeviceBaseFragment
      }
    }
  }
}
    ${JudgeBaseFragmentDoc}
${DeviceBaseFragmentDoc}`;

/**
 * __useJudgeStatusesQuery__
 *
 * To run a query within a Vue component, call `useJudgeStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJudgeStatusesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useJudgeStatusesQuery({
 *   groupId: // value for 'groupId'
 * });
 */
export function useJudgeStatusesQuery(variables: JudgeStatusesQueryVariables | VueCompositionApi.Ref<JudgeStatusesQueryVariables> | ReactiveFunction<JudgeStatusesQueryVariables>, options: VueApolloComposable.UseQueryOptions<JudgeStatusesQuery, JudgeStatusesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<JudgeStatusesQuery, JudgeStatusesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<JudgeStatusesQuery, JudgeStatusesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<JudgeStatusesQuery, JudgeStatusesQueryVariables>(JudgeStatusesDocument, variables, options);
}
export function useJudgeStatusesLazyQuery(variables?: JudgeStatusesQueryVariables | VueCompositionApi.Ref<JudgeStatusesQueryVariables> | ReactiveFunction<JudgeStatusesQueryVariables>, options: VueApolloComposable.UseQueryOptions<JudgeStatusesQuery, JudgeStatusesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<JudgeStatusesQuery, JudgeStatusesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<JudgeStatusesQuery, JudgeStatusesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<JudgeStatusesQuery, JudgeStatusesQueryVariables>(JudgeStatusesDocument, variables, options);
}
export type JudgeStatusesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<JudgeStatusesQuery, JudgeStatusesQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ... on Device {
      id
      name
      battery {
        batteryLevel
        updatedAt
      }
    }
    ... on User {
      id
      name
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const ResultsDocument = gql`
    query Results($groupId: ID!, $categoryId: ID!, $singleCategory: Boolean!) {
  group(groupId: $groupId) {
    ...GroupBaseFragment
    category(categoryId: $categoryId) @include(if: $singleCategory) {
      ...CategoryBaseFragment
      ...CategoryPrintFragment
      ...CategoryResultsFragment
      rankedResults {
        ...RankedResultBaseFragment
      }
    }
    categories @skip(if: $singleCategory) {
      ...CategoryBaseFragment
      ...CategoryPrintFragment
      ...CategoryResultsFragment
      rankedResults {
        ...RankedResultBaseFragment
      }
    }
  }
}
    ${GroupBaseFragmentDoc}
${CategoryBaseFragmentDoc}
${CategoryPrintFragmentDoc}
${CategoryResultsFragmentDoc}
${RankedResultBaseFragmentDoc}`;

/**
 * __useResultsQuery__
 *
 * To run a query within a Vue component, call `useResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResultsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useResultsQuery({
 *   groupId: // value for 'groupId'
 *   categoryId: // value for 'categoryId'
 *   singleCategory: // value for 'singleCategory'
 * });
 */
export function useResultsQuery(variables: ResultsQueryVariables | VueCompositionApi.Ref<ResultsQueryVariables> | ReactiveFunction<ResultsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ResultsQuery, ResultsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ResultsQuery, ResultsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ResultsQuery, ResultsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ResultsQuery, ResultsQueryVariables>(ResultsDocument, variables, options);
}
export function useResultsLazyQuery(variables?: ResultsQueryVariables | VueCompositionApi.Ref<ResultsQueryVariables> | ReactiveFunction<ResultsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ResultsQuery, ResultsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ResultsQuery, ResultsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ResultsQuery, ResultsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ResultsQuery, ResultsQueryVariables>(ResultsDocument, variables, options);
}
export type ResultsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ResultsQuery, ResultsQueryVariables>;
export const EntriesScoresheetsChangedDocument = gql`
    subscription EntriesScoresheetsChanged($entryIds: [ID!]!) {
  scoresheetChanged(entryIds: $entryIds)
}
    `;

/**
 * __useEntriesScoresheetsChangedSubscription__
 *
 * To run a query within a Vue component, call `useEntriesScoresheetsChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEntriesScoresheetsChangedSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useEntriesScoresheetsChangedSubscription({
 *   entryIds: // value for 'entryIds'
 * });
 */
export function useEntriesScoresheetsChangedSubscription(variables: EntriesScoresheetsChangedSubscriptionVariables | VueCompositionApi.Ref<EntriesScoresheetsChangedSubscriptionVariables> | ReactiveFunction<EntriesScoresheetsChangedSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<EntriesScoresheetsChangedSubscription, EntriesScoresheetsChangedSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<EntriesScoresheetsChangedSubscription, EntriesScoresheetsChangedSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<EntriesScoresheetsChangedSubscription, EntriesScoresheetsChangedSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<EntriesScoresheetsChangedSubscription, EntriesScoresheetsChangedSubscriptionVariables>(EntriesScoresheetsChangedDocument, variables, options);
}
export type EntriesScoresheetsChangedSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<EntriesScoresheetsChangedSubscription, EntriesScoresheetsChangedSubscriptionVariables>;